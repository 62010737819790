import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/about.css'
import { StaticImage } from "gatsby-plugin-image";
import CheckServices from '../components/CheckServices';
import ConsultationBanner from '../components/ConsultationBanner';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
   
  }
`;
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));
const AboutPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
//   const projectNodes = (data || {}).blogs
//     ? mapEdgesToNodes(data.blogs)
//         .filter(filterOutDocsWithoutSlugs)
//         .filter(filterOutDocsPublishedInTheFuture)
//     : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  function GridItem(props) {
    return (
      // From 0 to 600px wide (smart-phones), I take up 12 columns, or the whole device width!
      // From 600-690px wide (tablets), I take up 6 out of 12 columns, so 2 columns fit the screen.
      // From 960px wide and above, I take up 25% of the device (3/12), so 4 columns fit the screen.
      <Grid item xs={12} sm={6} md={4}>
        <Paper className={props.classes.paper}>{props.children}</Paper>
      </Grid>
    );
  }
   const classes = useStyles();
  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
     
      <div className="top-banner">
        <Navbar/>
<StaticImage className="banner" src='./images/2.png' alt="about us"/>

        </div>
        <div className="about">
    
        
        <div className='about-content'>

        <div className="sub-section">
       <div className="left-heading"><span className="brush-stroke">Who We Are </span></div>
       <div className="content"> We are a bunch of creatives and strategists who love storytelling, creating visuals, writing and day dreaming. By profession we are all managers who sleepwalk through our days at the office all the while thinking about all things brands and luxury. We are luxury enthusiasts and brand managers with a passion for everything we do.</div>
       </div>
        <div className="sub-section">
       <div className="left-heading">Our Purpose</div>
       <div className="content">The Brand Digest was founded with the intent to help business owners build their brands and create powerful associations amongst their audiences. Our second purpose is to help simplify the luxury industry for all our fellow luxury enthusiasts and help them gain a more practical, real-world understanding of the industry. We want to create a community of like-minded people who possess a similar set of values and principles.</div>
       </div>
        <div className="sub-section">
       <div className="left-heading">Our Belives <br/> And Values</div>
       <div className="content">
       <Grid container spacing={2}>
        <GridItem  classes={classes} >    <span>Minimalism</span></GridItem>
        <GridItem classes={classes}> <span>Simplicity</span></GridItem>
        <GridItem classes={classes}><span>Aesthetics</span> </GridItem>
        <GridItem classes={classes}><span>Creativity</span></GridItem>
        <GridItem classes={classes}><span>Originality</span></GridItem>
        <GridItem classes={classes}><span>Responsibility</span></GridItem>
        <GridItem classes={classes}>
<span>Authenticity</span> </GridItem>
      </Grid>
       





 </div>
       </div>
       
       
       
       </div>
       <h2 className="meet-founder-heading"> Meet the Founder</h2>
       <div className="meet-founder">
       
        <div className="meet-founder-content">

{/* <StaticImage src="./images/ishita-png.png" alt="Ishita Choudhary"/> */}
<div className="founder-name">Ishita Choudhary</div>
<p className="meet-founder-description"> 
Ishita founded The Brand Digest in 2021 after years of experience working as a Brand Manager, Luxury Consultant and an International Marketer. With a background in business, she’s always been interested in the business of luxury and brands in dynamic, diverse environments. A caffeinated bookworm with an extreme affinity for the outdoors, she enjoys working in the field and letting her creative juices take over.
</p>
<div className="social-links-container"> 
<a  Target="_Blank" href="https://www.linkedin.com/in/ishita-choudhary-833abb130" className="socail-link founder-link"><StaticImage style={{height:"50px",width:"50px"}} imgStyle={{height:"50px",width:"50px"}}  src="./images/linkedin-black.png" alt="Linkedin"   /></a>
<a  Target="_Blank" href="https://www.behance.net/ishitachoudhary_" className="socail-link founder-link"><StaticImage layout="fixed" style={{height:"50px",width:"50px"}} imgStyle={{height:"50px",width:"50px"}} src="./images/behance.png" alt="Behance"   /></a>

</div>
{/* <StaticImage className="founding-message" src="./images/founding-message.png" alt="Ishita founded The Brand Digest in 2021 after years of experience working as a Brand Manager, Luxury Consultant and an International Marketer. With a background in business, she’s always been interested in the business of luxury and brands in dynamic, diverse environments. A caffeinated bookworm with an extreme affinity for the outdoors, she enjoys working in the field and letting her creative juices take over."/> */}
        </div>  
        <div className="meet-founder-image"> 
        <StaticImage style={{width: '100%', height:"100%"}} imgStyle={{objectFit:'contain'}} src="./images/Meet-The-Founder.jpg" alt="meet the founder"/>
       
        </div>
       </div>
    
        </div>
  
    </Layout>
  );
};

export default AboutPage;
